.tableContainer {
    background-color: #f1f3f6;
    padding: 17px;
    border-radius: 5px;
  }
  .tableHover:hover {
    background-color: #cffcb6;
  }
  
  .tableHeader {
    background-color: #001e3c;
  }
  
  .tableCell {
    color: #fff !important;
    font-weight: 700 !important;
  }
  
 
  
  .assignmentIcon {
    font-size: 56px !important;
    color: #fff !important;
    background-color: #001e3c !important;
    margin-right: 15px !important;
  }
  
  .btn {
    background-color: #003366 !important;
    color: #fff !important;
    text-transform: capitalize !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-right: 5px !important;
  }

  
  .designationBtn:hover {
    background-color: #003366 !important;
    color: #fff !important;
  }
  .topContainer {
    background-color: #f1f3f6;
    padding: 17px;
    border-radius: 5px;
  }
  