.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}


.assignmentIcon {
  font-size: 56px !important;
  color: #fff !important;
  background-color: #001e3c !important;
  margin-right: 15px !important;
}

.receiptBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.receiptBtn:hover {
  background-color: #2b7b00 !important;
  color: #fff !important;
}

.paymentContainer{
    background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.paymentForm{
  background-color:#e4e4e4;
  padding: 5px 30px 30px 30px;
  margin: 15px 30px 30px 30px;
  
}

.addSupplier form{
    background-color: rgb(255, 255, 255);
    padding: 50px;
}

.addSupplierField{
    line-height: 10px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 6px;
    background-color: rgb(250, 250, 250);
    border: 1px solid rgb(205, 205, 205);
    box-shadow: 2px 2px 10px rgb(255, 255, 255) inset,
                -2px -2px 10px rgb(213, 213, 213) inset;
}
.addSupplierFieldLabel{
  text-align: left !important;
  font-weight: bold !important;
  font-size: 15px !important;
  padding-bottom: 10px;
  
}

.addSupplierFieldLabel span{
    color: red !important;
}

.supplierTextField{
  width: 100%;
  background-color: #f1f3f6;
}


.addSupplierButton{
    color: rgb(255, 255, 255) !important;
    font-weight: 600 !important;
    border: none !important;
    transition: 0.5s !important;
    background-color: #003366  !important;
    width: 300px;
    height: 40px;
}
.addSupplierButton:hover{
    background-color: #01284e  !important;
}


         