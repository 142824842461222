.tableContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}
.tableHover:hover {
  background-color: #cffcb6;
}

.tableHeader {
  background-color: #001e3c;
}

.tableCell {
  color: #fff !important;
  font-weight: 700 !important;
}

.editIcon {
  background-color: #003366;
  color: #fff;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.deleteIcon {
  background-color: #ee101b;
  color: #fff;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.assignmentIcon {
  font-size: 56px !important;
  color: #fff !important;
  background-color: #001e3c !important;
  margin-right: 15px !important;
}

.designationBtn {
  background-color: #003366 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}

.designationBtn:hover {
  background-color: #003366 !important;
  color: #fff !important;
}

.addEmployeeBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.addEmployeeBtn:hover {
  background-color: #45c203 !important;
  color: #fff !important;
}

.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.updateBtn {
  background-color: #87c3ff !important;
  color: rgb(3, 0, 0) !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}

.updateBtn:hover {
  background-color: #006ddb !important;
  color: #fff !important;
}

.receiptBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.receiptBtn:hover {
  background-color: #2b7b00 !important;
  color: #fff !important;
}

.addSupplierField {
  margin-bottom: 15px;
  padding: 10px;
  background-color: rgb(243, 243, 243);
  border: 1px solid rgb(205, 205, 205);
}
