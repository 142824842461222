.tableContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.tableHover:hover {
  background-color: #cffcb6;
}

.tableHeader {
  background-color: #001e3c;
}

.editIcon {
  background-color: #003366;
  color: #fff;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.deleteIcon {
  background-color: #ee101b;
  color: #fff;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.tableCell {
  color: #fff !important;
  font-weight: 700 !important;
}

.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.resetBtn {
  background-color: #e7575e !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-right: 5px !important;
}

.saveBtn {
  background-color: #003366 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-right: 5px !important;
}
