.paymentContainer{
    background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.assignmentIcon {
  font-size: 56px !important;
  color: #fff !important;
  background-color: #001e3c !important;
  margin-right: 15px !important;
}


.paymentBtn {
  background-color: #003366 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.paymentBtn:hover {
  background-color: #004a94 !important;
  color: #fff !important;
}

.receiptBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.receiptBtn:hover {
  background-color: #2b7b00 !important;
  color: #fff !important;
}

.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.paymentForm{
  background-color:#e4e4e4;
  padding: 5px 30px 30px 30px;
  margin: 15px 30px 30px 30px;
  
}
.inputContainer{
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  padding: 22px;
  border-radius: 6px;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(205, 205, 205);
  box-shadow: 2px 2px 10px rgb(255, 255, 255) inset,
                -2px -2px 10px rgb(213, 213, 213) inset;
}
.cardPay{
border: 1px solid rgb(205, 205, 205);
background-color: rgb(250, 250, 250);
 box-shadow: 2px 2px 10px rgb(255, 255, 255) inset,
                -2px -2px 10px rgb(213, 213, 213) inset;
}
.inputFields{
  width: 100%;
   background-color: #f1f3f6;
}
.inputTitle{
  font-weight: bold;
  padding-bottom: 10px;
}