.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.assignmentIcon {
  font-size: 56px !important;
  color: #fff !important;
  background-color: #001e3c !important;
  margin-right: 15px !important;
}

.paymentBtn {
  background-color: #003366 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.paymentBtn:hover {
  background-color: #004a94 !important;
  color: #fff !important;
}

.receiptBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.addLastBtn {
  background-color: #2c95ff !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.addLastBtn:hover {
  background-color: #004a94 !important;
  color: #fff !important;
}

.receiptBtn:hover {
  background-color: #2b7b00 !important;
  color: #fff !important;
}

.paymentContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.addSupplier form {
  background-color: rgb(255, 255, 255);
  padding: 50px;
}

.addSupplierField {
  line-height: 10px;
}

.addSupplierField span {
  color: red;
}
.addAnotherButton,
.addSupplierButton {
  color: rgb(255, 255, 255);
  font-weight: 600;
  border: none;
  transition: 0.5s;
}

.addSupplierButton {
  background-color: #642dfc;
}

.addSupplierButton:hover {
  background-color: #26008f;
}
.addAnotherButton {
  background-color: #008993;
}

.addAnotherButton:hover {
  background-color: #00484d;
}

.tableContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.tableHeader {
  background-color: #001e3c;
}

.tableCell {
  color: #fff !important;
  font-weight: 700 !important;
}
