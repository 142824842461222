.tableContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}
.tableHover:hover {
  background-color: #cffcb6;
}

.tableHeader {
  background-color: #001e3c;
}

.tableCell {
  color: #fff !important;
  font-weight: 700 !important;
}

.editIcon {
  background-color: #003366;
  color: #fff;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.assignmentIcon {
  font-size: 56px !important;
  color: #fff !important;
  background-color: #001e3c !important;
  margin-right: 15px !important;
}

.manageLoanBtn {
  background-color: #003366 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}
.addLoanBtn {
  background-color: #53d4fa !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}
.addPaymentBtn {
  background-color: #ffb61e !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}

.designationBtn:hover {
  background-color: #003366 !important;
  color: #fff !important;
}

.addPersonBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}

.addEmployeeBtn:hover {
  background-color: #45c203 !important;
  color: #fff !important;
}

.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}
