.tableContainer {
    background-color: #f1f3f6;
    padding: 17px;
    border-radius: 5px;
  }
  
  .tableHeader {
    background-color: #001e3c;
  }
  
  .tableCell {
    color: #fff !important;
    font-weight: 700 !important;
  }
  
  .assignmentIcon {
    font-size: 56px !important;
    color: #fff !important;
    background-color: #001e3c !important;
    margin-right: 15px !important;
  }
  
  .topContainer {
    background-color: #f1f3f6;
    padding: 17px;
    border-radius: 5px;
  }
  