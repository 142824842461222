.topContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.tableContainer {
  background-color: #f1f3f6;
  padding: 17px;
  border-radius: 5px;
}

.assignmentIcon {
  font-size: 56px !important;
  color: #fff !important;
  background-color: #001e3c !important;
  margin-right: 15px !important;
}

.addEmployeeBtn {
  background-color: #003366 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 5px !important;
}

.addEmployeeBtn:hover {
  background-color: #003366 !important;
  color: #fff !important;
}

.manageEmployeeBtn {
  background-color: #45c203 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.manageEmployeeBtn:hover {
  background-color: #45c203 !important;
  color: #fff !important;
}
